document.addEventListener("turbolinks:load", () => {
	console.log("hola desde users.js");
	//ckeck de form >si usa subgrupos
	let check_module_fields = document.querySelectorAll(".check-modulo");
	let check_all_fields = document.querySelectorAll(".check-all");
	//si esta en el form
	if (check_module_fields.length > 0 || check_all_fields.length > 0){
	  check_module_fields.forEach(el => {
		  el.addEventListener('click', () => {
		    let module_value = el.checked;
		    let parentEls = el.closest('tr');
	        checkboxes = parentEls.querySelectorAll("input[type=checkbox]")
	        checkboxes.forEach(ch => {
	        if (module_value) {
	        	ch.setAttribute('checked', module_value);
	        }else {
	        	ch.removeAttribute('checked');
	        }
	        })
		  });
	  });

	  check_all_fields.forEach(el => {
		  el.addEventListener('click', () => {
		    let module_value = el.checked;
		    el.closest("form").querySelectorAll("input[type=checkbox]").forEach(ch => {
		        if (module_value) {
		        	ch.setAttribute('checked', module_value);
		        }else {
		        	ch.removeAttribute('checked');
		        }
		    })
		  });
	  });

	  let modal_open_buttons = document.querySelectorAll('.preferences');
	  let add_user_preferences = document.getElementById("add-user-preferences");
	  let preferences_container = document.getElementById("user_preferences");
	  
	  modal_open_buttons.forEach((button) => {
		  button.addEventListener('click', () => {
			  button.dataset.toggle = "modal";
			  button.dataset.target = `#${button.dataset.id}`;
			  button.dataset.toggleClass = "modal-open-aside";
	  
			  // Comprueba si el elemento con el ID ya existe
			  let element = preferences_container.querySelector(`#${button.dataset.id}`);
			  if (!element) {
				  $('#user_preferences').one('cocoon:after-insert', (e, insertedItem, originalEvent) => {
					  // Asignar el id al modal
					  $(insertedItem).attr('id', button.dataset.id);
					  // Asignar el titulo del modal
					  $(insertedItem).find('.modal-title').text(button.dataset.name);
					  let rdm_number = Math.round(Math.random() * 100000000000);
					  $(insertedItem).find('input[data-type="table-name"]').val(button.dataset.id)

					  $.ajax({
						url: `/get_columns.json?model=${button.dataset.id}`,
						method: 'GET',
						success: function(data) { 
							// console.log(data);
							$(insertedItem).find('input[data-type="table-name"]').attr('id', `user_user_preferences_attributes_${rdm_number}_table_name`);
							$(insertedItem).find('input[data-type="table-name"]').attr('name', `user[user_preferences_attributes][${rdm_number}][table_name]`);
							// Construir los checkbox con los nombres de las columnas 
							data.forEach((column)=>{
								let checkbox_container = document.createElement("div");
								checkbox_container.className = "form-check";

								let checkbox = document.createElement("input");
								checkbox.className = "form-check-input";
								checkbox.type = "checkbox";
								checkbox.id = `user_user_preferences_attributes_${rdm_number}_visible_columns`;
								checkbox.name = `user[user_preferences_attributes][${rdm_number}][visible_columns][]`;
								checkbox.value = column[0];
								checkbox.checked = true;

								let label = document.createElement("label");
								label.className = "form-check-label";
								label.textContent = column[1];

								checkbox_container.appendChild(checkbox);
								checkbox_container.appendChild(label);
								$(insertedItem).find('#modal-content').append(checkbox_container);
								// console.log(checkbox_container);
							})
						},
                		error: function(jqXHR, textStatus, errorThrown) {
                		    console.error('Error al obtener los datos:', textStatus, errorThrown);
                		}
					  })
				  });
				  add_user_preferences.click();
			  }
		  });
	  });
	  
	}
});