document.addEventListener("turbolinks:load", () => {
	console.log('hola desde application_component.js');

  const photoswipeImages = document.querySelectorAll(".photoswipe-images")
  photoswipeImages.forEach(function(elem, index){
    const array = JSON.parse(elem.dataset.images || "[]")
    if(array.length > 0) {
      elem.addEventListener('click', ev => {
        slider(array)
      })
    }
  });

  function slider(array) {
    //console.log(array)
    var pswpElement = document.querySelectorAll('.pswp')[0];
  
    // build items array
    var items = [];
    array.forEach(function(elem, index){
      items.push({
            src: elem,
            w: 1875,
            h: 1875
        });
    });
  
    // define options (if needed)
    var options = {
        // optionName: 'option value'
        // for example:
        index: 0, // start at first slide
      zoomEl: true,
      shareEl: false,
      clickToCloseNonZoomable: false,
      history: false,
      closeOnScroll: false
    };
  
    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.listen('gettingData', function(index, item) {
          if (item.w < 1 || item.h < 1) { // unknown size
            var img = new Image(); 
            img.onload = function() { // will get size after load
              item.w = this.width; // set image width
              item.h = this.height; // set image height
                 gallery.invalidateCurrItems(); // reinit Items
                 gallery.updateSize(true); // reinit Items
            }
          img.src = item.src; // let's download image
        }
    });	
    gallery.init();
  }
  

	let pagination_controls = document.querySelector('.pagination');
	if (pagination_controls) {
		let table_scroll = document.getElementById('scroll-table');
		if (table_scroll) {
			document.getElementById("main").addEventListener('scroll', ()=>{
				let href_next = document.querySelector('.pagination .next_page');
				let url = (href_next ? href_next.getAttribute('href') : null);
				if (url) {url+="&scrolling=true"}
	    	let condition = document.getElementById("main").scrollTop > table_scroll.scrollHeight - 800;
        if (url && condition){
		  	  document.querySelector('.pagination').innerText = "Cargando mas elementos...";
		  	  $.getScript(url);
		  	  table_scroll.scroll();
	    	}
		  });
		}
	}

	$(".action-menu").click(function(){
		if (document.getElementsByClassName("menu-active").length){
			document.body.classList.remove("menu-active");
		}else{
			document.body.classList.add("menu-active");
		}		
	})


	reloading_scripts = () => {
		// está en menu_right_component
		addListenerRows(); // añadir callback a los trs para el click derecho
		// esta en batches_component.js
    let table_batches = document.getElementById('batches');
		if (table_batches) {
      addBatchExpenseCallbacks(); // batch expenses callback nuevo gasto por corte
      changeStatusBatchCallbacks(); // añadir callbacks de cambiar status esta en batches_component	
    }
	}

  // exportar elementos
  let exportBtn = document.getElementById('export-btn');
  if (exportBtn){
    let exportTable = () => {
      let params = $("#all_search").serialize() + "&sin_paginacion=si";
      // console.log(params)
      location.href = `${$("#all_search").attr('action')}.xlsx?${params}`
    }

    exportBtn.addEventListener('click', ev => {
      ev.preventDefault();
      exportTable();
    })
  }

  // exportar elementos sin buscador
  let exportBtnWF = document.getElementById('export-btn-without-filters');
  if (exportBtnWF){
    console.log('si hay boton sin buscador')
    let exportTableWF = () => {
      let params = `value=${exportBtnWF.dataset.value}`;
      // console.log(params)
      location.href = `${exportBtnWF.dataset.route}.xlsx?${params}`
    }

    exportBtnWF.addEventListener('click', ev => {
      ev.preventDefault();
      exportTableWF();
    })
  }
  
  $(".dynamic-select").select2({
    tags: true
  });
  // Initializer all Select2
  $('form select').not('.dynamic-select').not('.select-normal').select2();
  // fix para los seslect2 en modales no se abrian
  $('form select').not('.dynamic-select').not('.select-normal').each(function() {
    if ($(this).closest('.modal').length > 0) { 
      $(this).select2({ dropdownParent: $(this).parent()});
    }
  });
  // workaround select2 does not fire change of select element and some actions were not fired 
  $('form select').not('.dynamic-select').not('.select-normal').on('select2:select', function (e) {
    e.target.dispatchEvent(new Event("change")) 
  });
  // workaround select2 is not readonly when pointer-events:none as a conventional select, so we force it
  $('form select').not('.dynamic-select').not('.select-normal').on('select2:opening', function (e) {
    if( $(this).css('pointer-events') == 'none') {
        e.preventDefault();
        $(this).select2('close'); 
        return false;
    }
  });

  $('.rangedatepicker').each(function () {
    flatpickr(this, {
      mode: "range",
      dateFormat: "d/m/Y",
      altInput: true,
      altFormat: "d/m/Y",
      locale: {
        rangeSeparator: " - "
      },
      onClose: function(selectedDates, dateStr, instance) {
        if (selectedDates.length === 2) {
          $(instance.input).trigger('dates-selected', dateStr);
        }
      }
    });
  }); 

});


observeChangesOnNode = (node_id, cb) => {
  // Select the node that will be observed for mutations
  const targetNode = document.getElementById(node_id);
    if (targetNode) {
      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      const callback = function(mutationsList, observer) {
        cb($(node_id));
        // Use traditional 'for loops' for IE 11
        for(const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            console.log('A child node has been added or removed.');
          }
          else if (mutation.type === 'attributes') {
            console.log('The ' + mutation.attributeName + ' attribute was modified.');
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);
    }else {
      console.error("onChangeElement: Invalid Selector");
  }
}


show_toast = (text, icon_type) => {
  $.toast({
    text: text, // Text that is to be shown in the toast
    heading: 'Información', // Optional heading to be shown on the toast
    icon: icon_type, // Type of toast icon [success warning info error]
    showHideTransition: 'fade', // fade, slide or plain [slide fade plain]
    allowToastClose: false, // Boolean value true or false
    hideAfter: 3000, // false to make it sticky or number representing the miliseconds as time after which toast needs to be hidden
    stack: true, // false if there should be only one toast at a time or a number representing the maximum number of toasts to be shown at a time
    position: 'top-right', // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values
    textAlign: 'left',  // Text alignment i.e. left, right or center
    loader: true,  // Whether to show loader or not. True by default
    loaderBg: '#000',  // Background color of the toast loader
    beforeShow: function () {}, // will be triggered before the toast is shown
    afterShown: function () {}, // will be triggered after the toat has been shown
    beforeHide: function () {}, // will be triggered before the toast gets hidden
    afterHidden: function () {}  // will be triggered after the toast has been hidden
  });
}
