document.addEventListener("turbolinks:load", () => {
	console.log("hola desde sales.js");

	let document_field = document.getElementById("document_field");
	if (document_field) {
		// esta en el formulario de ventas
		let name_model = document_field.dataset.type; //si es venta o compra
		let linked_to_field = document.getElementById("linked_to_field-"+name_model);
		let model_path = document_field.dataset.modelPath;
		//cambiar el select de "desde" en una venta o compra
		let changeLinkedToSalePurchase = () => {
			let linked_to_field_value = linked_to_field.value;
			//dejar venta/compra como al cargar la pagina
			cleanFormSalePurchase();
			if (linked_to_field_value != "directa") {

				let search_term_field = document.getElementById("search_parent_document-field");
				// $(".loader").show();
				fetch("/"+model_path+"/possible_parents?document="+linked_to_field_value+(search_term_field ? ("&search_term=" + search_term_field.value) : ""))
					.then(resp => resp.json())
					.then(resp => {
						// console.log(resp);
						if (Array.isArray(resp)) {
						let options = "";
						resp.forEach((doc) => {
							if (linked_to_field_value == "pedido") {
								options += "<tr>"+
								"<td>pedido</td>"+
								"<td>"+doc.format+"</td>"+
								"<td>"+doc.publication_date+"</td>"+
								"<td>$"+doc.cost+"</td>"+
								"<td>"+doc.store+"</td>"+
								"<td>"+
								"<span class='btn btn-sm btn-success white-text' data-id='"+doc.id+"' data-type='select-parent-linked-to-field' title='Seleccionar'>+</span>"+
								"</td>"+
							"</tr>";
							} else { 
								options += "<tr>"+
								"<td>"+doc.document+"</td>"+
								"<td>"+doc.number+"</td>"+
								"<td>"+doc.inventory_movement.date+"</td>"+
								"<td>$"+doc.total+"</td>"+
								"<td>"+doc.name_subject+"</td>"+
								"<td>"+
								"<span class='btn btn-sm btn-success white-text' data-id='"+doc.id+"' data-type='select-parent-linked-to-field' title='Seleccionar'>+</span>"+
								"</td>"+
								"</tr>";
							}
						});

						let modal_title = "Seleccionar documento";
						let modal_content = "<div class='row'>"+
			      '<div class="field col-md-12"><input form="undefined" class="form-control" type="text" id="search_parent_document-field", value="'+(search_term_field ? search_term_field.value : "")+'""></div>'+
						"<div class='col-md-12 table-responsive'>"+
							"<table class='table'>"+
								"<thead><tr><th></th><th>Número</th><th>Fecha</th><th>Total</th><th>Cli./Prov.</th><th></th></tr></thead>"+
								"<tbody>"+options+"</tbody>"+
							"</table>"+
						"</div>";
							
						document.querySelector("#sharedModal .modal-title").innerHTML = modal_title;
						document.querySelector("#sharedModal .modal-body").innerHTML = modal_content;
						document.querySelector("#sharedModal .modal-footer").innerHTML = '<button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>';
						
						// input para buscar	
						document.getElementById("search_parent_document-field").addEventListener("change", (ev) => {
							ev.preventDefault();
							changeLinkedToSalePurchase();
						});

						document.querySelectorAll("[data-type='select-parent-linked-to-field']").forEach(elem => {
							elem.addEventListener("click", ev => {
								if (linked_to_field_value == "pedido") {
									selectOrderToDocument(elem.dataset.id);
								}else {
									selectParentLinkedToDocument(elem.dataset.id);
								}
							})
						});
					} else {
						document.querySelector("#sharedModal .modal-body").innerHTML = `
  							<div class="d-flex align-items-center justify-content-center" style="min-height: 200px;">
  							  <div class="row">
  							    <div class="col text-center">
  							      <h5>No se encontraron resultados</h5>
  							    </div>
  							  </div>
  							</div>`;	
					}
						$("#sharedModal").modal("show");
					}).catch(error => {
						console.log(error)
					});

				//boton para seleccionar venta/compra desde
				document.getElementById("change-linked-to-btn").classList.remove('d-none');
				document.getElementById("document_inventory_movement_field").setAttribute("readonly", "true");

				//quitar el boton cuando son devolucones/cancelaciones ligadas
				let concept_code_key = document.getElementById("concept_code_key_field").value;
				//cuando son devoluciones/cancelaciones no se muestra el boton de agregar
				without = ["52", "57", "2", "4"];
				if (without.filter(el => concept_code_key == el ).length > 0) {
					document.getElementById("add-movement-product").classList.add('d-none');
				}
			}else {
				//si es una operacion directa mostrar boton de agregar
				document.getElementById("add-movement-product").classList.remove('d-none');
			}
		}

		//poner informacion al cambiar cliente proveedor en ventas/comprass
		let setClientProviderInfo = () => {
			let id_linked_to_field = document.getElementById('name_linked_to_field');
			let id_linked_to = id_linked_to_field.value;
			// console.log(id_linked_to);
			type_linked_to_value = document.getElementById("linked_to_field").value;
			// console.log(type_linked_to_value)
			if (id_linked_to && type_linked_to_value) {
				// console.log(id_linked_to_field.dataset)
				let route = (type_linked_to_value == "Client" ? id_linked_to_field.dataset.routeClients : id_linked_to_field.dataset.routeProviders)
				// $(".loader").show();
				fetch("/"+route+"/"+id_linked_to+".json")
					.then(resp => resp.json())
					.then(resp => {
						// console.log(resp);
						document.getElementById("rfc-field").value = resp.rfc;
						document.getElementById("show-address").classList.remove('d-none');
						document.getElementById("name-field").value = resp.name;
						document.getElementById("street-field").value = resp.street;
						document.getElementById("district-field").value = resp.district;
						document.getElementById("num_int-field").value = resp.no_int;
						document.getElementById("num_ext-field").value = resp.no_ext;
						document.getElementById("cp-field").value = resp.cp;
						document.getElementById("population-field").value = resp.township;
						document.getElementById("country-field").value = resp.country;
						if (document.getElementById("send_to-field")) {
							document.getElementById("send_to-field").value = resp.shipping_address;
						}
						if (document.getElementById("addressee-field")) {
							document.getElementById("addressee-field").value = resp.addressee;
						}
						document.getElementById("discount_percent-field").value = resp.discount_percent;
						// console.log(resp.seller_id)
						if (document.getElementById("seller_id-field")) {
							document.getElementById("seller_id-field").value = resp.seller_id;
							if (resp.seller_id){
								document.getElementById("seller_id-field").dispatchEvent(new Event("change"));
							}
						}
						//poner si el cliente/proveedor tiene credito
						id_linked_to_field.dataset.creditManagment =  resp.credit_management;
						id_linked_to_field.dataset.daysCredit =  resp.credit_days;
					}).catch(error => {
						console.log(error);
				});
			}else {
				$("#seller_id-field,#rfc-field,#name-field,#street-field,#district-field,#num_int-field,#num_ext-field,#cp-field,#population-field,#country-field,#send_to-field,#addressee-field").val("");
				// quitar tiene credito
				id_linked_to_field.dataset.creditManagment = null;
				id_linked_to_field.dataset.daysCredit = null;
				document.getElementById("discount_percent-field").value = 0;
			}
		}

		let setSellerInfo = () => {
			let seller_id = document.getElementById('seller_id-field').value;
			fetch("/vendedores/"+seller_id+".json")
				.then(resp => resp.json())
				.then(resp => {
					document.getElementById("sales_commission_percent-field").value = resp.commission_percent;
				}).catch(error => {
					console.log(error);
				});
		}

		//confirmar venta/compra abre modal y totales
		let openModalConfirmSale = () => {
			//if ($("form#form-"+clase+"s").parsley().isValid()) {
				$("#confirm_transaction").modal("show");
				let total = (document.getElementById("total_movement-field").value || 0);
				document.getElementById("subtotal_field").value = total;
				let porcent_discount_one = (document.getElementById("discount_percent-field").value || 0);
				// let porcent_discount_two = (document.getElementById("discount_percent_two-field").value || 0);
				// let percent_ieps = (document.getElementById("percent_ieps-field").value || 0);
				// let percent_ret_irs = (document.getElementById("percent_ret_isr-field").value || 0);
				// let percent_ret_iva = (document.getElementById("percent_ret_iva").value || 0);
				let percent_iva = (document.getElementById("percent_iva_field").value || 0);
				let percent_commision = (document.getElementById("sales_commission_percent-field").value || 0);//ventas
				// let indirect_spend = (document.getElementById("gastos_indirectos").val() || 0);//compras

				let quantity_disc_one = (porcent_discount_one * total) / 100;
				// let quantity_disc_two = (porcent_discount_two * total) / 100;
				// let quantity_ieps = (percent_ieps * total) / 100;
				// let quantity_ret_isr = (percent_ret_irs * total) / 100;
				// let quantity_ret_iva = (percent_ret_iva * total) / 100;
				let quantity_iva = (percent_iva * total) / 100;
				let quantity_commision = (percent_commision * total) / 100; //ventas

				let result = total;
				let document_field = document.getElementById('document_field').value;
				if (document_field == 'devolucion') {
					document.getElementById("discount_field").value = 0;
				} else {
					document.getElementById("discount_field").value = quantity_disc_one;
					result = result - quantity_disc_one + quantity_iva
				}
				// document.getElementById("discount_two_field").value = quantity_disc_two;
				// document.getElementById("ieps_field").value = quantity_ieps;
				// document.getElementById("ret_isr_field").value = quantity_ret_isr;
				// document.getElementById("ret_iva_field").value = quantity_ret_iva;
				document.getElementById("iva_field").value = quantity_iva
				let sales_comission_field = document.getElementById("sales_commission_field");
				if (sales_comission_field) {
					sales_comission_field.value = quantity_commision;//ventas
				}
				// document.getElementById("gastos_indirectos_c").val(gind);//compras
				document.getElementById("total_field").value = result;

				//poner cantidad en el modal de forma de pago
				document.getElementById("total_modal_payment_method").innerHTML = "$" + result;
			//}else {
				// $("form#form-"+clase+"s").parsley().validate()
			//}
		}

		//abrir el modal de forma de pago 
		//le pone las opciones egun si el cliente/proveedor tiene permitido credito
		let openModalConfirmPaymentMethod = () => {
			$("#confirm_transaction").modal("hide");
			
			$("#payment_method").modal("show");
			let arr = [["Contado", "contado"]]
			let subject = document.getElementById("name_linked_to_field");
			let credit_management = subject.dataset.creditManagment;
			//  console.log(credit_management)
			let expiration_date = new Date();
			let credit_days = parseInt(subject.dataset.daysCredit || 0);
			if (credit_management) {
				// console.log(credit_days)
				arr.push(["Crédito C/Enganche", "credito_enganche"],["Crédito S/Enganche", "credito_sin_enganche"])//,["A plazos", "plazos"])
				expiration_date = new Date(expiration_date.setDate(expiration_date.getDate() + credit_days));
				expiration_date = expiration_date.getFullYear() + "-" + ("0"+(expiration_date.getMonth()+1)).slice(-2) + "-" + ("0" + expiration_date.getDate()).slice(-2);
			}
			let options = "";
			arr.forEach(el => {
				options += "<option value='"+el[1]+"'>"+el[0]+"</option>"
			});
			document.getElementById("type_pm-field").innerHTML = options;
			document.getElementById("cash_pm-field").removeAttribute('readonly');
			document.getElementById("cash_pm-field").value = 0;

			document.getElementById("bank_transfer_pm-field").removeAttribute('readonly');
			document.getElementById("bank_transfer_pm-field").value = 0;

			document.getElementById("cheque_pm-field").removeAttribute('readonly');
			document.getElementById("cheque_pm-field").value = 0;

			// console.log(expiration_date);
			document.getElementById("expiration_date_pm-field").value = expiration_date;
			document.getElementById("expiration_date_pm-field").setAttribute("title", credit_days+" dias de crédito");
			changePaymentMethod();
		}

		//cambiar el tipo de forma de pago
		let changePaymentMethod = () => {
			let type_pm = document.getElementById("type_pm-field").value;
			document.getElementById("cash_pm-field").value = 0
			document.getElementById("cash_pm-field").dispatchEvent(new Event("change"));
			document.getElementById("bank_transfer_pm-field").value = 0;
			document.getElementById("bank_transfer_pm-field").dispatchEvent(new Event("change"));
			document.getElementById("cheque_pm-field").value = 0;
			document.getElementById("cheque_pm-field").dispatchEvent(new Event("change"));

			switch (type_pm) {
				case "contado":
					document.getElementById("cash_pm-field").removeAttribute('readonly');
					document.getElementById("bank_transfer_pm-field").removeAttribute('readonly');
					document.getElementById("cheque_pm-field").removeAttribute('readonly');
					document.querySelectorAll(".expiration_date_pm-field").forEach((elem) => {
						elem.classList.add('d-none');
					})
					break;
				case "credito_enganche":
					document.getElementById("cash_pm-field").removeAttribute('readonly');
					document.getElementById("bank_transfer_pm-field").removeAttribute('readonly');
					document.getElementById("cheque_pm-field").removeAttribute('readonly');
					document.querySelectorAll(".expiration_date_pm-field").forEach((elem) => {
						elem.classList.remove('d-none');
					})
					break;
				case "credito_sin_enganche":
					document.getElementById("cash_pm-field").setAttribute('readonly', 'readonly');
					document.getElementById("bank_transfer_pm-field").setAttribute('readonly', 'readonly');
					document.getElementById("cheque_pm-field").setAttribute('readonly', 'readonly');
					document.querySelectorAll(".expiration_date_pm-field").forEach((elem) => {
						elem.classList.remove('d-none');
					})
					break;
				// case "plazos":
				// 	break;
			}
		}

		//total en el modal de forma de pago
		let changePaymentMethodTotal = () => {
			let cash_payment_method	= (document.getElementById("cash_pm-field").value || 0);
			let bank_transfer_payment_method = (document.getElementById("bank_transfer_pm-field").value || 0);
			let cheque_payment_method = (document.getElementById("cheque_pm-field").value || 0);
			let sum = parseFloat(cash_payment_method) + parseFloat(bank_transfer_payment_method) + parseFloat(cheque_payment_method);

			document.getElementById("total_pm-field").value = sum;
		}
		let selectOrderToDocument = id => {
			$("#sharedModal").modal("hide");
			fetch('/pedidos/'+id+'.json')
			.then(resp => resp.json())
			.then(data => {
				// console.log(data);
				let name_linked_field = document.getElementById("name_linked_to_field");
				name_linked_field.value = data.client_id;
				$(name_linked_field).trigger("change")
				name_linked_field.setAttribute('style', 'pointer-events:none');
				//CamposRelacionales
				document.getElementById("linked_to_id_field").value = data.id;
				document.getElementById("sale_reference_type").value = data.model_name;
				document.getElementById("sale_reference_id").value = data.id;
				document.getElementById("document_inventory_movement_field").value = data.format;
				document.getElementById("rfc-field").value = data.rfc;
				document.getElementById("name-field").value = data.name;
				document.getElementById("street-field").value = data.street;
				document.getElementById("num_ext-field").value = data.no_ext;
				document.getElementById("district-field").value = data.district;
				document.getElementById("num_int-field").value = data.no_int;
				document.getElementById("cp-field").value = data.cp;
				document.getElementById("population-field").value = data.township;
				document.getElementById("country-field").value = data.country;
				if (data.deliver_date) {
					document.getElementById("deliver_date_field").value = data.deliver_date;
					document.getElementById("deliver_date_field").setAttribute("readonly","true");
				}
				document.getElementById("discount_percent-field").value = data.discount_percent;
				if (document.getElementById("seller_id-field")) {
					document.getElementById("seller_id-field").value = data.seller_id;
					document.getElementById("seller_id-field").dispatchEvent(new Event("change"));
				}
				document.getElementById("deliver_date_field").value = data.deliver_date;
			}).catch(error => {
				console.log(error);
			});
		}
		//seleccionar venta para campo enlazado_a_id
		let selectParentLinkedToDocument = id => {
			// console.log("se selecciono "+id);
			$("#sharedModal").modal("hide");
			// $(".loader").show();
			fetch('/'+model_path+'/'+id+'.json')
			.then(resp => resp.json())
			.then(data => {
				// console.log(data);
				let name_linked_field = document.getElementById("name_linked_to_field");
				let document_field = document.getElementById("document_field").value;
				name_linked_field.value = data.inventory_movement.meta_id;
				$(name_linked_field).trigger("change")
				name_linked_field.setAttribute('style', 'pointer-events:none');
				document.getElementById("show-address").classList.remove('d-none');
				document.getElementById("linked_to_id_field").value = data.id;
				document.getElementById("document_inventory_movement_field").value = data.number;
				document.getElementById("rfc-field").value = data.rfc;
				document.getElementById("name-field").value = data.name;
				document.getElementById("street-field").value = data.street;
				document.getElementById("num_ext-field").value = data.num_ext;
				document.getElementById("district-field").value = data.district;
				document.getElementById("num_int-field").value = data.num_int;
				document.getElementById("cp-field").value = data.cp;
				document.getElementById("population-field").value = data.population;
				document.getElementById("country-field").value = data.country;
				// document.getElementById("condition_field").val(data.condition).attr("readonly","true");
				if (document.getElementById("deliver_date_field")) {
					document.getElementById("deliver_date_field").value = data.deliver_date;
					document.getElementById("deliver_date_field").setAttribute("readonly","true");
				}
				if (document.getElementById("reception_date_field")) {
					document.getElementById("reception_date_field").value = data.reception_date;
					document.getElementById("reception_date_field").setAttribute("readonly","true");
				}
				if (document.getElementById("effective_date_field")) {
					document.getElementById("effective_date_field").value = data.effective_date;//.attr("readonly","true");
				}
				if (document.getElementById("send_to-field")) {
					document.getElementById("send_to-field").value = data.send_to;//.attr("readonly","true");
				}
				if (document.getElementById("addressee-field")) {
					document.getElementById("addressee-field").value = data.addressee;//.attr("readonly","true");
				}
				// document.getElementById("destinatario_dest").value = data.destinatario);//.attr("readonly","true");
				if (document.getElementById("your_order_field")) {
					document.getElementById("your_order_field").value = data.your_order;//.attr("readonly","true");
				}
				document.getElementById("discount_percent-field").value = data.discount_percent;//.attr("readonly", "true");
				// document.getElementById("descuento_dos_p").value = data.porcentaje_descuento_dos);//.attr("readonly", "true");
				if (document.getElementById("seller_id-field")) {
					document.getElementById("seller_id-field").value = data.seller_id;/*.attr({
					'style': 'pointer-events:none',
					'readonly': 'readonly'
					})*/
					document.getElementById("seller_id-field").dispatchEvent(new Event("change"));
				}
				if (document_field == 'devolucion') {
					document.getElementById("curp-field").value = data.provider.curp;
					document.getElementById("email-field").value = data.provider.email;
					document.getElementById("phone-field").value = data.provider.phone;
					document.getElementById("state-field").value = data.provider.condition;
				}
				// document.getElementById("comision_venta_p").value = data.porcentaje_comision_vendedor;//.attr("readonly", "true");
				// document.getElementById("ieps_p").value = data.porcentaje_ieps;//.attr("readonly", "true");
				// document.getElementById("ret_isr_p").value = data.porcentaje_ret_isr;//.attr("readonly", "true");
				// document.getElementById("ret_iva_p").value = data.porcentaje_ret_iva;//.attr("readonly", "true");
				document.getElementById("percent_iva_field").value = data.percent_iva;//.attr("readonly", "true");
				// document.getElementById("gastos_indirectos").value = data.gastos_indirectos;//.attr("readonly", "true");
				document.getElementById("total_movement-field").value = data.total
				document.getElementById("warehouse-id-field").value = data.inventory_movement.warehouse_id;/*.attr({
					'style': 'pointer-events:none',
					'readonly': 'readonly'
				})*/
				document.getElementById("observations_inventory_movement-field").value = data.inventory_movement.observations;
				
				let btn_add_product = document.getElementById("add-movement-product");
				let add_product_id = btn_add_product.dataset.associationInsertionNode;
				//console.log(add_product_id);
				data.product_movements.forEach( product => {
		  			//insertar el nested
		  			let time         = Math.round(Math.random() * 100000000000);
		  			let regexp       = new RegExp("new_product_movements", 'g')
		  			let fields_html  = btn_add_product.dataset.associationInsertionTemplate.replace(regexp, time);
		  			$(add_product_id).append(fields_html);
		  			let base_id = "#"+name_model+"_inventory_movement_attributes_product_movements_attributes_";
		  			
		  			// llenar el nested ------seleccionando el que es
						// console.log(producto);
						let parent = document.querySelector(base_id+time+"_unit_cost").closest("tr.nested-fields");
						let random_number = Math.round(Math.random() * 100000000000);
						parent.setAttribute('data-id', random_number);
						document.getElementById("id_whom_open_modal").value = random_number;
						// se le envia el precio/costo que tenia en el otro documento
						// selectProductInModal(product.warehouse_stock_id, product.unit_cost);
						if (product.product_lot){
							let btn_add_lot = parent.querySelector("[data-type='select-lot-btn-nested']");
							let fields_html_lots = btn_add_lot.dataset.linkToAddLot
							.replace("producto_lote_valor", product.product_lot.id)
							.replace("lote_valor", product.product_lot.key_code)
							.replace("fecha_produccion_valor", product.product_lot.production_date)
							.replace("fecha_caducidad_valor", product.product_lot.expiration_date);
							console.log(fields_html_lots);
							parent.querySelector('[data-type="movements-lots-box"]').innerHTML = fields_html_lots;
							btn_add_lot.classList.remove("d-none");
							btn_add_lot.classList.replace('btn-warning','btn-success');
						}
						// modificar valores en el nested ------ los valores del documento
		  			document.querySelector(base_id+time+"_warehouse_id").value = product.warehouse_id;
		  			document.querySelector(base_id+time+"_warehouse_id").setAttribute("style", "pointer-events:none");
					document.querySelector(base_id+time+"_warehouse_stock_id").value = product.warehouse_stock_id;
					
					$('#inventory_movement_id').val(product.inventory_movement_id);
					let quantity_nested_field = document.querySelector(base_id+time+"_quantity");
		  			quantity_nested_field.value = product.quantity;
		  			quantity_nested_field.max = product.quantity;

					document.querySelector(base_id+time+"_product_key_code").value = product.product_key_code;
					document.querySelector(base_id+time+"_product_description").value = product.product_description;

		  			setTimeout(() => {
							document.querySelector(base_id+time+"_unit_cost").value = product.unit_cost;
		  				document.querySelector(base_id+time+"_unit_cost").dispatchEvent(new Event("change"));
		  			}, 2000);
					  
					quantity_nested_field.addEventListener("change", (e) => {
						  calculateSubtotalMovementNested(quantity_nested_field);
						  let subtotal_nesteds = document.querySelectorAll('input[data-type="subtotal-nested-field"]');
						  // console.log(subtts);
						  let total = 0;
						  // console.log(subtotal_nesteds)
						  subtotal_nesteds.forEach(function(el) {
						  	if (!!( el.offsetWidth || el.offsetHeight || el.getClientRects().length )) {
						  		// console.log('visible');
						  		total += parseFloat(el.value||0);
						  	}
						  });
						//   let percent_iva = (data.percent_iva || 0);
						//   let discount = parseFloat((data.discount_percent * total) / 100);
						//   let result = (total - discount) + parseFloat(percent_iva);
						  
						  let total_field = document.getElementById("total_movement-field");
						  if(total_field) { total_field.value = total };
					});
		  			document.querySelector(base_id+time+"_subtotal").value = product.subtotal - parseFloat((data.discount_percent * product.subtotal) / 100) + parseFloat(data.percent_iva || 0);
					document.getElementById("message-discount").innerText = `Se aplico un ${data.discount_percent}% de descuento`;
					document.getElementById("message-discount").classList.remove("d-none");
		  			document.querySelector(base_id+time+"_subtotal").dispatchEvent(new Event("change"));
						calculateTotalCostMovement();
				});
			}).catch(error => {
				console.log(error);
			});
		}

		//limpiar el formulario de venta/compra como esta al inicio al cargar la pagina
		let cleanFormSalePurchase = () => {
			//boton para seleccionar venta/compra desde
			document.getElementById("change-linked-to-btn").classList.add('d-none');
			document.getElementById("show-address").classList.add('d-none');

			let name_linked_to_field = document.getElementById("name_linked_to_field");
			name_linked_to_field.value = ""
			name_linked_to_field.removeAttribute('style')
			name_linked_to_field.dispatchEvent(new Event("change"));
			document.getElementById("linked_to_id_field").value = "";

			document.getElementById("document_inventory_movement_field").value = ""
			document.getElementById("document_inventory_movement_field").removeAttribute('readonly');

			// document.getElementById("condition_field").value = "";
			// document.getElementById("condition_field").removeAttribute("readonly");
			if (document.getElementById("deliver_date_field")) { // ventas
				document.getElementById("deliver_date_field").removeAttribute("readonly");
			}
			if (document.getElementById("reception_date_field")) { // compras
				document.getElementById("reception_date_field").removeAttribute("readonly");
			}
			//document.getElementById("effective_date_field").removeAttribute("readonly");
			if (document.getElementById("send_to-field")) {
				document.getElementById("send_to-field").removeAttribute("readonly");//ventas
			}
			if (document.getElementById("deliver_to-field")) {
				document.getElementById("deliver_to-field").removeAttribute("readonly");//ventas
			}
			if (document.getElementById("addressee-field")) {
				document.getElementById("addressee-field").removeAttribute("readonly");
			}
			if (document.getElementById("your_order_field")) {
				document.getElementById("your_order_field").value = "";
				document.getElementById("your_order_field").removeAttribute("readonly");
			}
			document.getElementById("discount_percent-field").value = "0";
			document.getElementById("discount_percent-field").removeAttribute("readonly");
			
			// document.getElementById("descuento_dos_p").val("0").removeAttribute("readonly");
			// document.getElementById("seller_id-field").value = "";.removeAttribute('style').removeAttribute('readonly').dispatchEvent(new Event("change"));;
			// document.getElementById("comision_venta_p").removeAttribute("readonly");
			// document.getElementById("ieps_p").val("0").removeAttribute("readonly");
			// document.getElementById("ret_isr_p").val("0").removeAttribute("readonly");
			// document.getElementById("ret_iva_p").val("0").removeAttribute("readonly");
			
			document.getElementById("percent_iva_field").value = "0"
			document.getElementById("percent_iva_field").removeAttribute("readonly");
			
			// document.getElementById("gastos_indirectos").val("0").removeAttribute("readonly");
			
			document.getElementById("warehouse-id-field").value = "";
			document.getElementById("warehouse-id-field").removeAttribute('style')
			document.getElementById("warehouse-id-field").removeAttribute('readonly')
			document.getElementById("warehouse-id-field").dispatchEvent(new Event("change"));
			
			document.getElementById("observations_inventory_movement-field").value = "";
			document.getElementById("observations_inventory_movement-field").removeAttribute("readonly");
			
			document.getElementById("total_movement-field").value ="0";
			//limpiar el div de los productos
			document.getElementById("nesteds-product-movements").innerHTML = "";
			document.getElementById("message-discount").classList.add("d-none");
			
		}



		//-----------INICIALIZAR ELEMENTOS Y PONER CALLBACKS FORMULARIO DE VENTAS/COMPRAS------------
		// select 'desde' que dice si tiene parent
		linked_to_field.addEventListener("change", (ev) => {
			changeLinkedToSalePurchase();
		});

		// boton de seleccionar parent
		document.getElementById("change-linked-to-btn").addEventListener("click", (ev) => {
			changeLinkedToSalePurchase();
		});

		// seleccionar cliente
		document.getElementById("name_linked_to_field").addEventListener("change", (ev) => {
			setClientProviderInfo();
		});
		// seleccionar vendedor
		let seller_id_field = document.getElementById("seller_id-field");
		if (seller_id_field) {
			seller_id_field.addEventListener("change", (ev) => {
				setSellerInfo();
			});
		}

		//boton confirmar venta/compra
		document.getElementById("confirm-transaction-btn").addEventListener("click", (ev) => {
			openModalConfirmSale();
		});

		// boton confirmar forma de pago
		if (btnPMv = document.getElementById("confirm-payment-method-btn")) {
			btnPMv.addEventListener("click", (ev) => {
				openModalConfirmPaymentMethod();
			});
		}
		// btn open payment method
		document.getElementById("type_pm-field").addEventListener("change", (ev) => {
			changePaymentMethod();
		});

		document.getElementById("show-address").addEventListener("click", (ev) => {
			$("#addressModal").modal('show')
			// changePaymentMethod();
		});

		document.querySelectorAll("[data-type='calculate-total-payment-method']").forEach((elem) => {
			elem.addEventListener("change", (ev) => {
				changePaymentMethodTotal();
			});
		})
	}
	$(".button-total").on('click', function () {
		const value = $(this).data("value");
		const formattedValue = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);

		$("#total-text").text(formattedValue);
	});

	$(document).on('click', function () {
		const initialValue = $("#total-text").data("value");
		if (initialValue && !$(event.target).closest('.button-total').length) {
			// console.log("CHANGE");
			const formattedInitialValue = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(initialValue);	
			$("#total-text").text(formattedInitialValue);
		}
	});
});