document.addEventListener('turbolinks:load', () => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    console.log("Delete button");
    document.body.addEventListener('click', function (event) {
        if (event.target.classList.contains('delete')) {
            event.preventDefault();

            const button = event.target;
            const deleteUrl = button.getAttribute('data-url');

            Swal.fire({
                title: 'Estás a punto de eliminarlo',
                text: "¿Estás seguro de eliminar el registro?",
                icon: 'warning',
                iconColor: '#d33',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#646464',
                confirmButtonText: `<i class="fa fa-trash"></i>&nbspEliminar`,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(deleteUrl, {
                        method: 'DELETE',
                        headers: {
                            'X-CSRF-Token': csrfToken,
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }).then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                          return response.json().then(data => {
                            console.log(data);
                            throw new Error(data ? data.join(', ') : 'Ocurrió un error');
                          });
                        }
                    }).then(data => {
                        Swal.fire(
                          '¡Éxito!',
                          data.notice,
                          'success'
                        ).then(() => {
                          window.location.href = data.redirect_url;
                        });
                    }).catch((error) => {
                        console.error('Error:', error);
                        Swal.fire(
                          '¡Error!',
                          error.message,
                          'error'
                        ).then(() => {
                            Turbolinks.visit(location);
                        });
                    });
                }
            });
        }
    });
});
