document.addEventListener("turbolinks:load", async function () {
    console.log('hola desde clients.js');
    let showTab = document.getElementById('showTab');
    if (showTab) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        let metaField = document.getElementById('metaField');
        let route = metaField.dataset.type == 'Provider' ? 'proveedores' : 'clientes';

        try {
            await createSalesPurchasesGraph(currentYear);
            await createAccountsGraph(currentMonth, 'monthly');
            await getAccountsByExpiration('unexpired', '1-30');
            await getAccountsByExpiration('expired', '1-30');
        } catch (error) {
            console.error("Error en la ejecución de las funciones:", error);
        }

        $(".document-type").on('click', function (event) {
            event.preventDefault();
            let document = $(this).data('type');
            getDocumentsByMeta(document);
            $(".document-type").removeClass("active");
            $(this).addClass("active");
        });

        $("#collection-forecast-select, #balance-age-select").on('change', function () {
            let selectId = $(this).attr('id');
            if (selectId == 'collection-forecast-select') {
                getAccountsByExpiration('unexpired', $(this).val());
            } else {
                getAccountsByExpiration('expired', $(this).val());
            }
        });

        $("#month").on('change', function () {
            let month = $(this).val();
            createAccountsGraph(month, 'monthly');
        })

        $("#date-range").on('dates-selected', function () {
            let date_range = $(this).val();
            createAccountsGraph(date_range, 'range');
        })

        $("#years_select").on('change', function () {
            let year = $(this).val();
            createSalesPurchasesGraph(year);
        })

        async function createSalesPurchasesGraph(year) {
            let response = await fetch(`/${route}/documents_by_month?id=${metaField.value}&year=${year}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            if (response.ok) {
                let data = await response.json()
                createColumnChart(data.chart_values, "#grafica-ventas", data.months);

            } else {
                console.error('Error al obtener datos');
            }
        }

        async function createAccountsGraph(param, type) {
            let params = '';
            if (type == 'monthly') {
                params = `month=${param}`;
            } else {
                const [startDate, endDate] = param.split(' - ');
                params = `start_date=${startDate}&end_date=${endDate}`;
            }
            let response = await fetch(`/${route}/accounts_with_payments?id=${metaField.value}&${params}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            if (response.ok) {
                let data = await response.json()
                createTwoColumnsChart(data.accounts_with_payments, "#grafica-cuentas", data.account_invoices);

                let total_accounts =  document.getElementById("total-accounts");
                let total_payments =  document.getElementById("total-payments");

                total_accounts.innerHTML = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.total_accounts_amount)
                total_payments.innerHTML = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.total_payments_amount)
            } else {
                console.error('Error al obtener datos');
            }
        }

        async function getAccountsByExpiration(type, range) {
            let response = await fetch(`/${route}/accounts_by_expiration?id=${metaField.value}&type=${type}&range=${range}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            if (response.ok) {
                let data = await response.json()
                let content = "";
                if (data.length > 0) {
                    data.forEach((account) => {
                        content += `<li class="list-group-item">
                                            <div class="mb-1">
                                              <h5 class="mb-0">${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(account.amount)}</h5>
                                            </div>
    
                                            <div class="d-flex justify-content-between">
                                              <span class="fs-5">Folio: <a href="/cuentas/${account.id}">${account.invoice}</a></span>
                                              <span class="fs-6 text-muted">Fecha de vencimiento: ${new Date(account.expiration_date).toLocaleDateString('es-MX', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                                            </div>
                                        </li>`;
                    })
                } else {
                    content = '<li class="list-group-item"><h5 class="text-center">Sin registros</h5></li>'
                }
                if (type == 'expired') {
                    let listExpired = document.getElementById('balance-age');
                    listExpired.innerHTML = content;
                } else {
                    let listUnexpired = document.getElementById('collection-forecast');
                    listUnexpired.innerHTML = content;
                }
                // console.log(data);

            } else {
                console.error('Error al obtener datos');
            }
        }

        function getDocumentsByMeta(doc) {
            let body_table = document.getElementById('body-sales');
            fetch(`/${route}/document_by_meta?id=${metaField.value}&document=${doc}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then(response => response.json())
                .then(data => {
                    let content = "";
                    if (data.length > 0) {
                        data.forEach((doc) => {
                            content += `<tr>
                                            <td>${doc.document}</td>
                                            <td>${doc.deliver_date}</td>
                                            <td>${doc.name}</td>
                                            <td>${doc.linked_to}</td>
                                            <td>${(doc.linked_to_id ? doc.linked_to_id : 'N/A')}</td>
                                            <td>${(doc.seller_name ? doc.seller_name : 'N/A')}</td>
                                            <td>${doc.subtotal}</td>
                                            <td>${doc.discount}</td>
                                            <td>${doc.iva}</td>
                                            <td>${doc.total}</td>
                                        </tr>`;
                        })
                    } else {
                        content = '<tr><td colspan="10" class="text-center">Sin registros</td></tr>'
                    }
                    body_table.innerHTML = content;
                    // console.log(data);

                })
                .catch(error => console.error('Error fetching data:', error));
        }

        function createTwoColumnsChart(data, selector, categories) {
            var options = {
                series: [{
                    name: 'Cuentas',
                    data: data.map(item => item.total_account) // Total de las cuentas
                },
                {
                    name: 'Pagos',
                    data: data.map(item => item.total_payments) // Total de los pagos
                }
                ],
                chart: {
                    height: 250,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: categories, // folio de las cuentas
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                colors: ['#377dff', '#cdd3dc']
            };

            let graph = document.querySelector(selector);
            if (graph) { graph.innerHTML = '' }

            var chart = new ApexCharts(document.querySelector(selector), options);
            chart.render();
        }

        function createColumnChart(data, selector, xaxis) {
            var options = {
                series: [{
                    data: data
                }],
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: xaxis,
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                colors: ['#377dff']
            };

            let graph = document.querySelector(selector);
            if (graph) { graph.innerHTML = '' }

            var chart = new ApexCharts(document.querySelector(selector), options);
            chart.render();
        }
    }
})